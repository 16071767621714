import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  HStack,
  Heading,
  IconButton,
  Text,
  Box,
  Image,
  Input,
  useColorModeValue,
  useToast,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react';
import { FaEdit } from 'react-icons/fa';
import {updatePlaylist, deletePlaylist} from '../api'; 
import ProfileImage from './ProfileImage';

const EditPlaylistModal = ({ 
  playlist,
  playlistId,
  playlistTitle,
  userId, 
  colorMode,
  // userProfile: initialUserProfile, 
  // playlist: initialPlaylist,
  // not sure if on profileUpdate is needed here
  onProfileUpdate, 
  onPlaylistUpdate,
  // change profpic trigger to ?
  // playlistCoverPicUpdateTrigger
  // setPlaylistCoverPicUpdateTrigger
  profilePicUpdateTrigger,
  setProfilePicUpdateTrigger  
}) => {

  const navigate = useNavigate();
  const [newPlaylistTitle, setNewPlaylistTitle] = useState('');
  const [playlistTitleError, setPlaylistTitleError] = useState('');
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

  const imagePath = playlist?.playlistFilms?.[0]?.tmdbImagePath;
  const imageBaseUrl = 'https://image.tmdb.org/t/p/original';
  const defaultImage = 'https://bit.ly/2Z4KKcF';
  const fullImgPath = imagePath ? `${imageBaseUrl}${imagePath}` : defaultImage;

  const bgColor = useColorModeValue("white", "black");
  const textColor = useColorModeValue("black", "white");
  const borderColor = useColorModeValue("black", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();  
  const toast = useToast();

  const handlePlaylistTitleChange = (e) => {
    setNewPlaylistTitle(e.target.value);
    validatePlaylistTitle(e.target.value);
  };

  const validatePlaylistTitle = (playlistTitle) => {
    if (playlistTitle.length === 0) {
      setPlaylistTitleError('');
    } else if (playlistTitle.length < 3 || playlistTitle.length > 20) {
      setPlaylistTitleError('must be between 3 and 20');
    } else {
      setPlaylistTitleError('');
    }
  };

  // need to refactorthis to handleUpdatePlaylistPic
  const handleUpdatePlaylistCoverPic = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('profilePic', file);

      try {
        const updatedUser = await updatePlaylist(userId, formData);
        setUserProfile(prevProfile => ({...prevProfile, ...updatedUser}));
        onProfileUpdate({...updatedUser, profilePicUpdated: true});
        setProfilePicUpdateTrigger(prev => prev + 1);  // Use the shared state updater
        toast({
          title: 'Profile Picture Updated',
          description: 'Your profile picture has been successfully updated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error',
          description: `${error.message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        console.error('Error updating profile picture:', error);
      }
    }
  };

  const handleUpdatePlaylistTitle = async (playlistTitle) => {
    try {
      const updatedPlaylist = await updatePlaylist(playlistId, { playlistTitle });
      onPlaylistUpdate(updatedPlaylist);
      toast({
        title: 'Playlist Updated',
        description: 'The playlist title has been successfully updated.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      // onClose();
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'An error occurred while updating the playlist title.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeletePlaylist = () => {
    setIsDeleteAlertOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await deletePlaylist(playlistId);
      toast({
        title: 'Playlist Deleted',
        description: 'The playlist has been successfully deleted.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteAlertOpen(false);
      onClose();
      navigate('/profile/' + userId); // Redirect to the user's profile page
      
      // You might want to redirect the user or update the UI here
    } catch (error) {
      toast({
        title: 'Delete Failed',
        description: error.message || 'An error occurred while deleting the playlist.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button 
        m='2' 
        size='xs' 
        variant='outline' 
        onClick={onOpen}
        isDisabled={playlist?.isAllRatings}
      >
        Edit Playlist
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay 
        bg='none'
        backdropFilter='auto'
        backdropInvert='80%'
        backdropBlur='2px'
        />
        <ModalContent bg={bgColor} borderColor={borderColor} borderWidth="3px" borderRadius='15px'>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
              <VStack align="center" spacing={8}>
                <VStack align='center' spacing='2'>
                  <Heading size='md' fontFamily='categoryFont'>
                    Edit Playlist
                  </Heading>
                  <Box position='relative'>
                    <Image
                      src={fullImgPath}
                      alt="Profile"
                      width={'150px'}
                      height={'150px'}
                      objectFit="cover"
                      border='3px solid'
                      borderColor={borderColor}
                      className="profilePagePicFormat"
                      onError={(e) => {
                        setImageExists(false);
                      }}
                    />
                   {/* will add ability to upload custom images here? need to refactor this to handleUpdatePlaylistCoverPic */}
                    {/* <ProfileImage 
                      userId={userId} 
                      size='150px' 
                      updateTrigger={profilePicUpdateTrigger} 
                    /> */}
                    {/* <IconButton
                      aria-label="Edit profile picture"
                      icon={<FaEdit />}
                      position="absolute"
                      top="2px"
                      right="2px"
                      size="sm"
                      onClick={() => document.getElementById('profile-image-upload').click()}
                    /> */}
                  </Box>
                  <Input
                    type="file"
                    id="profile-image-upload"
                    style={{ display: 'none' }}
                    onChange={handleUpdatePlaylistCoverPic}
                  />
                </VStack>
                <VStack align='center' spacing='4'>
                  
                  <VStack align='start' spacing='.5'>
                    <Text color='gray.500' fontSize='xs'>
                    {/* Playlist Title - {playlist.playlistTitle} */}
                      Playlist Title - {playlistTitle}
                    </Text>
                    
                    <Input 
                      name="playlistTitle"
                      placeholder="new playlistTitle" 
                      value={newPlaylistTitle} 
                      onChange={handlePlaylistTitleChange}
                      isDisabled={playlist?.isAllRatings}
                    />
                    {playlistTitleError && <Text color='pink.400' fontSize='xs'>{playlistTitleError}</Text>}
                    {/* {usernameError && <Text color='pink.400' fontSize='xs'>{usernameError}</Text>} */}
                  </VStack>
                  
                  <Button 
                    size='sm' 
                    onClick={() => handleUpdatePlaylistTitle(newPlaylistTitle)}
                    isDisabled={!!playlistTitleError || 
                              newPlaylistTitle.trim() === '' || 
                              playlist?.isAllRatings}
                  >
                    update playlist title 
                  </Button>
                  <Button 
                    size='sm' 
                    colorScheme="red" 
                    onClick={handleDeletePlaylist}
                    isDisabled={playlist?.isAllRatings}
                  >
                    Delete Playlist
                  </Button>
                </VStack>
                {/* <ProfileSection userProfile={userProfile} handleInputChange={handleInputChange} handleUpdate={handleUpdate} />; */}
                {/* {renderSection()} */}
              </VStack>
          </ModalBody>
          <ModalFooter>
            {/* <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={undefined}
        onClose={() => setIsDeleteAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent 
            bg="black" 
            borderColor="white" 
            borderWidth="3px" 
            borderRadius='15px'
          >
            <AlertDialogHeader fontSize="lg" fontWeight="bold" color="white">
              Delete Playlist
            </AlertDialogHeader>

            <AlertDialogBody color="red.600">
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button 
                onClick={() => setIsDeleteAlertOpen(false)}
                bg="black"
                color="white"
                borderColor="white"
                borderWidth="1px"
                _hover={{ bg: "gray.800" }}
              >
                Cancel
              </Button>
              <Button 
                colorScheme="red" 
                onClick={confirmDelete} 
                ml={3}
                bg="red.500"
                _hover={{ bg: "red.600" }}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EditPlaylistModal;
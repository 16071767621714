import React, { useState, useEffect } from 'react';
import { useMedia } from 'react-use';
import {
  Box,
  VStack,
  Center,
  SimpleGrid,
  Spinner,
  Heading,
  HStack,
  Button,
  Text,
} from '@chakra-ui/react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import PlaylistFilmCard from './PlaylistFilmCard';
import AltNav from './AltNav';
import MobileAltNav from './MobileAltNav';
import { getPlaylist, getUserProfile, updatePlaylist, followPlaylist, unfollowPlaylist } from '../api';
import ProfileImage from './ProfileImage';
import EditPlaylistModal from './EditPlaylistModal';
import { FaShare } from 'react-icons/fa';
import { useToast } from '@chakra-ui/react';
import FollowButton from './FollowButton';

const PlaylistPage = (props) => {
  const {
    setLoggedIn,
    loggedIn,
    handleLogout,
    customSwitchChecked,
    setCustomSwitchChecked,
    colorMode,
    loggedInUserId,
    toggleColorMode
  } = props;

  const {playlistId, userId} = useParams();
  const [playlistTitle, setPlaylistTitle] = useState(null); 
  const [playlistFilms, setPlaylistFilms] = useState(null); 
  const [playlistFollowers, setPlaylistFollowers] = useState(null);
  const [playlist, setPlaylist] = useState(null); 
  const [userProfile, setUserProfile] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const isMobile = useMedia('(max-width: 767px)');
  const [profilePicUpdateTrigger, setProfilePicUpdateTrigger] = useState(0);
  const [selectedFilm, setSelectedFilm] = useState([]);
  const location = useLocation();
  const locationUserProfile = location.state?.userProfile;
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchPlaylistAndUserData = async () => {
      setIsLoading(true);
      try {
        const playlistData = await getPlaylist(playlistId);
        setPlaylistTitle(playlistData.playlist.playlistTitle);
        setPlaylistFilms(playlistData.playlist.playlistFilms);
        setPlaylistFollowers(playlistData.playlist.followers);
        setPlaylist(playlistData.playlist);

        // Set the follower count
        setFollowerCount(playlistData.playlist.followers.length);
  
        let playlistOwner;
        if (locationUserProfile && locationUserProfile._id === playlistData.playlist.user_id) {
          playlistOwner = locationUserProfile;
        } else {
          const ownerData = await getUserProfile(playlistData.playlist.user_id);
          playlistOwner = ownerData.user;
        }
        setUserProfile(playlistOwner);
  
      } catch (error) {
        console.error('Error fetching playlist or user data:', error);
        toast({
          title: "Error",
          description: "Failed to load playlist or user data.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchPlaylistAndUserData();
  }, [playlistId, locationUserProfile, loggedInUserId, toast]);

  
  // useEffect(() => {
  //   const fetchPlaylistAndUserData = async () => {
  //     setIsLoading(true);
  //     try {
  //       const playlistData = await getPlaylist(playlistId);
  //       setPlaylistTitle(playlistData.playlist.playlistTitle);
  //       setPlaylistFilms(playlistData.playlist.playlistFilms);
  //       setPlaylistFollowers(playlistData.playlist.followers);
  //       setPlaylist(playlistData.playlist);
  //       // console.log('playlist data check' + playlistData.playlist);

  //       if (locationUserProfile) {
  //         setUserProfile(locationUserProfile);
  //         // console.log('location user profile check' + locationUserProfile.username);
  //       } else {
  //         const userData = await getUserProfile(playlistData.playlist.user_id);
  //         setUserProfile(userData.user);
  //         // console.log('user data check' + userData.user);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching playlist or user data:', error);
  //       toast({
  //         title: "Error",
  //         description: "Failed to load playlist or user data.",
  //         status: "error",
  //         duration: 3000,
  //         isClosable: true,
  //       });
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchPlaylistAndUserData();
  // }, [playlistId, locationUserProfile, toast]);


  const handleRemoveFilm = async (filmId) => {
    try {
      await updatePlaylist(playlistId, { $pull: { playlistFilms: filmId } });
      setPlaylistFilms(prevFilms => prevFilms.filter(film => film._id !== filmId));
      toast({
        title: "Film removed",
        description: "The film has been removed from the playlist.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error removing film from playlist:', error);
      toast({
        title: "Error",
        description: "Failed to remove film from playlist.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNavigateToProfile = () => {
    // console.log("Navigate function called");
    // console.log('user profile kai check' , userProfile._id);
    if (userProfile?._id) {
      navigate(`/profile/${userProfile._id}`);
    } else {
      toast({
        title: "Error",
        description: "Unable to navigate to user profile. User ID is missing.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (playlist && loggedInUserId) {
      setIsFollowing(playlist.followers.includes(loggedInUserId));
    }
  }, [playlist, loggedInUserId]);
  
  const handleFollowToggle = async () => {
    try {
      if (isFollowing) {
        await unfollowPlaylist(playlistId);
        setIsFollowing(false);
      } else {
        await followPlaylist(playlistId);
        setIsFollowing(true);
      }
      // Refresh playlist data
      const playlistData = await getPlaylist(playlistId);
      setPlaylist(playlistData.playlist);
      setFollowerCount(playlistData.playlist.followers.length);
  
      // Update user's followedPlaylists
      const updatedUserData = await getUserProfile(loggedInUserId);
      setUserProfile(prevProfile => ({
        ...prevProfile,
        followedPlaylists: updatedUserData.user.followedPlaylists
      }));
    } catch (error) {
      console.error("Error toggling follow:", error);
      toast({
        title: "Error",
        description: "Failed to update follow status.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // need to change this? /scrap it and or make sure the playlist pic is pulled correctly from the first item
  const handleProfileUpdate = async (updatedProfile) => {
    setUserProfile(prevProfile => ({...prevProfile, ...updatedProfile}));
    if (updatedProfile.profilePicUpdated) {
      setProfilePicUpdateTrigger(prev => prev + 1);
    }
  };

  const handleShare = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      toast({
        title: "Link Copied!",
        description: "The link to this playlist has been copied to your clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }, (err) => {
      console.error('Could not copy text: ', err);
      toast({
        title: "Error",
        description: "Failed to copy link. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  return (
    <>
      <VStack width="100%" align="stretch">
        <Center>
          {isMobile ? (
            <MobileAltNav
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
              // userId={userId}
              loggedInUserId={loggedInUserId}
            />
          ) : (
            <AltNav
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
              loggedInUserId={loggedInUserId}
              // userId={userId}
            />
          )}
        </Center>
      </VStack>

      <Box width="100%" overflowX="hidden">
      <VStack  spacing='4' paddingBottom={'10'} marginTop={isMobile ? '10' : '0'}>
        <VStack spacing={'0'}>
          <Heading class="playlistTitleFormat" letterSpacing={'wide'} >
            {playlistTitle}
          </Heading>
          <HStack h='20px' width ='500px' align='center' justify= 'center' spacing={'40px'}>
          <Heading class="profileUsernameFormat" letterSpacing={'wide'} >
              By{' '}
              <Text
                as="span"
                cursor="pointer"
                // color="qTurquoise.500"
                onClick={handleNavigateToProfile}
                _hover={{ textDecoration: 'underline', color: 'pink.400' }}
              >
                @{userProfile?.username || 'username'}
              </Text>
            </Heading>
            <Heading class={'profileUsernameFormat'}>
                {followerCount || 0} Followers
            </Heading>
          </HStack>
        </VStack>
          
        <ProfileImage 
          userId={userId} 
          size='80px'
          // updateTrigger={profilePicUpdateTrigger} 
        />
    
        <VStack spacing={2}>
          {/* will add the follow button back in iin the future for individual playlists  */}
            {/* {loggedIn && userId !== loggedInUserId && (
                <FollowButton 
                    isFollowing={isFollowing} 
                    handleFollowToggle={handleFollowToggle} 
                />
            )} */}
            {loggedIn && (
              userProfile?._id === loggedInUserId ? (
                <EditPlaylistModal 
                  playlistId={playlistId}
                  playlistTitle={playlistTitle}
                  playlist={playlist}
                  userId={userProfile?._id} 
                  userProfile={userProfile} 
                  onPlaylistUpdate={(updatedPlaylist) => {
                    setPlaylistTitle(updatedPlaylist.playlist.playlistTitle);
                    // Update other state as needed
                  }}
                  profilePicUpdateTrigger={profilePicUpdateTrigger}
                  setProfilePicUpdateTrigger={setProfilePicUpdateTrigger}
                  colorMode={colorMode}
                />
              ) : (
                <FollowButton 
                  isFollowing={isFollowing} 
                  handleFollowToggle={handleFollowToggle} 
                />
              )
            )}
            <Button
              leftIcon={<FaShare />}
              onClick={handleShare}
              mt='1'
              pr='-2'
              size='xs'
              colorScheme="pink"
            >
              {/* Share */}
            </Button>
        </VStack>
      </VStack>

      <VStack>
      {isLoading ? (
        <Spinner p="20" color="qTurquoise.500" size="xl" />
      ) : !playlistFilms || playlistFilms.length === 0 ? (
        <Box>
          <Text>No films yet</Text>
        </Box>
      ) : (
        <SimpleGrid columns={[2, 6]} spacing={[9, 20]} paddingTop={10} >
          {playlistFilms.map((film) => {
              const imagePath = film.tmdbImagePath;
              const imageBaseUrl = 'https://image.tmdb.org/t/p/original';
              const defaultImage = "https://images.unsplash.com/photo-1478720568477-152d9b164e26?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
              const fullImgPath = imagePath ? `${imageBaseUrl}${imagePath}` : defaultImage;
              // console.log('data check film id' + film._id);
            
            return(
                <div key={film._id}>
                    <PlaylistFilmCard
                      filmId={film._id}
                      tmdbFilmId={film.filmTmdbId}
                      title={film.filmTitle}
                      year={film.filmYear}
                      fullImgPath={fullImgPath}
                      avgQRating={film.filmQRatingAverage}
                      setSelectedFilm={setSelectedFilm} // Assuming setSelectedFilm is defined elsewhere
                      isEditMode={isEditMode}
                      onRemove={() => handleRemoveFilm(film._id)}
                    />
                </div>
            );      
          })}
        </SimpleGrid>
      )}
      </VStack>

      <VStack align="center" m='10'>
        {loggedIn && userProfile?._id === loggedInUserId && (
          <Button
            onClick={() => setIsEditMode(!isEditMode)}
            colorScheme={isEditMode ? "red" : "qPurple"}
            // mb={4}
            size="xs" 
            isDisabled={playlist?.isAllRatings}
          >
            {isEditMode ? "Done Editing" : "Edit Contents"}
          </Button>
        )}
      </VStack>
      </Box>
    </>
  );
};

export default PlaylistPage;
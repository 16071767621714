import React, { useState } from 'react';
import {
    HStack,
    VStack,
    Button,
    Box,
} from '@chakra-ui/react';
import CustomSwitch from "./CustomSwitch";
import { useNavigate } from 'react-router-dom';
import ActivityPopover from './ActivityPopover';

//think  we might be able to take out the values to search in this nav, because search won't be displayed when using this?
//unless we need to pass the search value from the previous view to this one?
const RateNav = (props) => {

    const {
        loggedIn,
        handleLogout,
        customSwitchChecked,
        setCustomSwitchChecked,
        colorMode,
        toggleColorMode,
        userId
    } = props;

    const navigate = useNavigate();

    const callLogOut = async () => {
        try {
            await handleLogout();
            navigate('/');
        } catch (e) {
            console.log("DED: ", e);
        }

    }

    const renderLoginButton = (loggedIn) => {
        if (!loggedIn) {
            return (
                <Button
                    letterSpacing='wider'
                    variant='ghost'
                    fontFamily="buttonFont"
                    onClick={() => {
                        navigate('/login');
                    }}
                > Login </Button>
            );
        } else {
            return (
                <Button
                        letterSpacing='wider'
                        variant='ghost'
                        fontFamily="buttonFont"
                        onClick={() => {
                            navigate(`/profile/${userId}`);
                        }}
                    >
                        Profile
                </Button>
                // <Button
                //     letterSpacing='wider'
                //     variant='ghost'
                //     fontFamily="buttonFont"
                //     onClick={() => {
                //         callLogOut();
                //     }}
                // >
                //     Logout
                // </Button>
            );
        }
    }

    const renderNotificationButton = (loggedIn) => {
        if (loggedIn) {
            return <ActivityPopover />;
        }
        return null;
    }

    return (
        <>
            <HStack position='absolute' width={'100%'} spacing='200px' justifyContent={"space-between"} >
                <VStack class= " alphaContainerdiv"  >
                    <Button id="home_button" alignSelf={'flex-start'}  minWidth='150px' variant='ghost' onClick={() => {navigate('/')}}></Button>
                </VStack>

                <HStack alignItems="start" spacing='-10px'>
                    <Box pt='2'>
                        {renderNotificationButton(loggedIn)}
                    </Box>
                    <VStack spacing={'-4px'} >
                        {renderLoginButton(loggedIn)}
                        <Button
                            letterSpacing='wider'
                            variant='ghost'
                            fontFamily="buttonFont"
                            onClick={() => {
                                navigate('/about');
                            }}
                        >
                            About
                        </Button>
                        <CustomSwitch
                            onColor={'#000'}
                            offColor={'#FFF'}
                            isChecked={customSwitchChecked}
                            handleSwitch={() =>{
                                setCustomSwitchChecked(!customSwitchChecked);
                                toggleColorMode();
                            }}
                        >
                        </CustomSwitch>
                    </VStack>
                </HStack>
            </HStack>
        </>
    );
};

export default RateNav;
import React, { useState, useEffect } from 'react';
import {
    HStack,
    VStack,
    Button,
    chakra,
    Box,
    Center,
    Image,
    useCheckbox,
    Text,
    useCheckboxGroup,
    Input,
    Icon,
    IconButton,
    Heading,
    useToast,
    useColorModeValue,
    Menu,
    MenuButton,
    Flex,
    MenuList,
    MenuItem,
    SimpleGrid
} from '@chakra-ui/react';
import {searchTmdbFilms, searchPlaylists, searchProfiles, fetchGenres, getTopFilmsByCategoryAndGenre, getFilmsByGenres} from '../api';
import CustomSwitch from "./CustomSwitch";
import { useNavigate } from 'react-router-dom';


function CustomCheckbox(props) {
    const { state, getInputProps, getLabelProps, htmlProps } =
        useCheckbox(props);
    
    if (state.isChecked) {
        return (
            <chakra.label
                display='flex'
                flexDirection='row'
                alignItems='center'
                gridColumnGap={2}
                bg='black.50'
                border='3px solid'
                borderColor='qPurple.500'
                rounded='lg'
                px={3}
                py={1}
                cursor='pointer'
                {...htmlProps}
            >
                <input {...getInputProps()} hidden />
                <Text
                    color={useColorModeValue('black', 'white')}
                    fontFamily='categoryFont'
                    letterSpacing="widest"
                    fontWeight='semibold'
                    {...getLabelProps()}
                    fontSize={'md'}
                    >    
                    {props.value}
                </Text>
            </chakra.label>
        )
    }
    return (
        <chakra.label
            display='flex'
            flexDirection='row'
            alignItems='center'
            gridColumnGap={2}
            bg='black.50'
            border='3px solid'
            // borderColor='black'
            borderColor={useColorModeValue('white', 'black')}
            rounded='lg'
            px={3}
            py={1}
            cursor='pointer'
            variant = {['mobile','baseStyle']}
            {...htmlProps}
        >
            <input {...getInputProps()} hidden />
            <Text
                // color="white"
                color={useColorModeValue('black', 'white')}
                fontFamily='categoryFont'
                letterSpacing="widest"
                fontWeight='semibold'
                // fontSize= '18px'
                {...getLabelProps()}
                fontSize={'md'}
                >
                
                {props.value}
            </Text>
        </chakra.label>
    )
}

function CategoryCheckbox(setCategories, homeClicked, setHomeClicked, isVisible) {
    const { value, getCheckboxProps, setValue } = useCheckboxGroup({});

    useEffect(() => {
        setCategories(value);
    }, [value]);

    useEffect(() => {
        setValue([]);
        setHomeClicked(false);
    }, [homeClicked]);

    if (!isVisible) return null;

    return (
        <VStack paddingTop='6' width='96%' spacing='' align='end' paddingBottom='4' >
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Plot' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Acting' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Cinematography' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Novelty' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Ending' })} />
        </VStack>
    )
}

const MobileTopNav = (props) => {

    const {
        setScene,
        loggedIn,
        handleLogout,
        setSearchFilmsResponse,
        searchFilmsResponse,
        customSwitchChecked,
        setCustomSwitchChecked,
        toggleColorMode,
        setFilterIsLoading,
        searchType,
        setSearchType,
        userId
    } = props;

    const [userSearchInput, setUserSearchInput] = useState('');
    const [categories, setCategories] = useState([]);
    const [homeClicked, setHomeClicked] = useState(false);
    const [genres, setGenres] = useState([]);
    const [showGenres, setShowGenres] = useState(false);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const toast = useToast();
    const [toastDisplayed, setToastDisplayed] = useState(false);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    // Add debounce function
    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    };

    const handleScroll = () => {
        if (loading) return; // Prevent fetching if already loading
        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200 && hasMore) {
            setCurrentPage((prevPage) => prevPage + 1); // Increment the page number
        }
    };
    
    // Debounce the scroll event
    useEffect(() => {
        const debouncedHandleScroll = debounce(handleScroll, 200);
        window.addEventListener('scroll', debouncedHandleScroll);
        return () => window.removeEventListener('scroll', debouncedHandleScroll);
    }, [hasMore, loading, selectedGenres]); // Added selectedGenres as dependency

    // LIGHT/DARK UI declarations
    // const value = useColorModeValue(lightModeValue, darkModeValue)
    // const borderBottomSearch = useColorModeValue('blue', 'red.200');
    // size is working based on this system, something about border will not let it get implimented, also possible to do
    //a variation of this through the theme.js file
    // const size = useColorModeValue('sm', 'lg');

    const CustomMenuIcon = () => (
        <img src={process.env.PUBLIC_URL + '/MenuWhite.svg'}
        width="50"
        height="50"
        alt="Menu Icon" />
      );

    const CustomMenuIconBlack = () => (
        <img src={process.env.PUBLIC_URL + '/MenuBlack.svg'}
        width="50"
        height="50"
        alt="Menu Icon" />
    );

    const onChangeKeyword = (event) => {
        setUserSearchInput(event.target.value);
    };

    const onSearch = async (event) => {
        event.preventDefault();
        setFilterIsLoading(true);
        try {
            let searchResults;
            if (searchType === 'films') {
                if (!userSearchInput.trim()) {
                    toast({
                        title: "Empty Film Search",
                        description: "Please enter a search term to find films.",
                        status: "warning",
                        duration: 3000,
                        isClosable: true,
                    });
                    setFilterIsLoading(false);
                    return;
                }
                const films = await searchTmdbFilms(userSearchInput);
                searchResults = films;
            } else if (searchType === 'playlists') {
                const playlists = await searchPlaylists(userSearchInput);
                searchResults = { results: playlists };
            } else if (searchType === 'profiles') {
                // For profiles, allow empty search
                const profiles = await searchProfiles(userSearchInput);
                searchResults = profiles;
            }
            setSearchFilmsResponse(searchResults.results);
            setScene('SEARCH_RESULTS');
        } catch (error) {
            console.error('Search error:', error);
            toast({
                title: "Search Error",
                description: "An error occurred while searching. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setFilterIsLoading(false);
        }
    };

    const onHomeClick = () => {
        document.querySelector("input").value = "";
        setCategories([]);
        setScene('HOME');
        setUserSearchInput('');
        setSelectedGenres([]);
        setSearchFilmsResponse([]);
        setHomeClicked(true);
        setSearchType('films');
        setToastDisplayed(false);
        setShowGenres(false); 
    }

    useEffect(() => {
        const fetchGenreData = async () => {
            try {
                const genreData = await fetchGenres();
                setGenres(genreData.genres);
            } catch (error) {
                console.error("Error fetching genres:", error);
            }
        };
        fetchGenreData();
    }, []);

    const fetchResults = async (page, clearResults = false) => {
        if (loading) return;
        setLoading(true);
        try {
           
            const newResults = await getFilmsByGenres(selectedGenres, page);
            
            if (clearResults) {
                setSearchFilmsResponse(newResults || []);
            } else {
                // Ensure searchFilmsResponse is treated as an array even if undefined
                const currentResults = searchFilmsResponse || [];
                const uniqueResults = (newResults || []).filter(newItem => 
                    !currentResults.some(existingItem => existingItem.id === newItem.id)
                );
                
                if (uniqueResults.length === 0) {
                    console.log('No new unique results, setting hasMore to false');
                    setHasMore(false);
                } else {
                    setSearchFilmsResponse(prevResults => [...(prevResults || []), ...uniqueResults]);
                }
            }
            setHasMore((newResults || []).length > 0);
        } catch (error) {
            console.error('Error fetching films:', error);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Reset everything when genres change
        setSearchFilmsResponse([]);
        setCurrentPage(1);
        setHasMore(true);
        setLoading(false);

        if (!isInitialLoad && categories.length === 0) {
            
            fetchResults(1, true);
        } else {
            setIsInitialLoad(false);
        }
    }, [selectedGenres]);

    useEffect(() => {
        // Only fetch next page if we have genres selected and no categories
        if (selectedGenres.length > 0 && categories.length === 0 && !isInitialLoad) {
            fetchResults(currentPage);
        }
    }, [currentPage]);

    useEffect(() => {
        const onCategoryAndGenreFilter = async () => {
            try {
                document.querySelector("input").value = "";
                setFilterIsLoading(true);
                
                if (categories.length === 0 && selectedGenres.length === 0) {
                    setScene('HOME');
                    setSearchFilmsResponse([]);
                } else if (selectedGenres.length > 0 && categories.length === 0) {
                    setScene('SEARCH_RESULTS');
                    await fetchResults(1, true);
                } else {
                    setScene('SEARCH_RESULTS');
                    const films = await getTopFilmsByCategoryAndGenre(categories, selectedGenres);
                    setSearchFilmsResponse(films);
                    
                    if (!toastDisplayed) {
                        toast({
                            title: "Top Category Filters",
                            description: "Display films rated (Well Above Average - 4) in the selected categories. Click to filter.",
                            status: "success",
                            duration: 12000,
                            isClosable: true
                        });
                        setToastDisplayed(true);
                    }
                }
            } catch (e) {
                console.error("Error in category and genre filtering:", e);
            } finally {
                setFilterIsLoading(false);
            }
        };
        
        onCategoryAndGenreFilter();
    }, [categories, selectedGenres]);

    const handleSearchTypeToggle = async (newType) => {
        setSearchType(newType);
        setUserSearchInput('');
        setSearchFilmsResponse([]);
        setShowGenres(false);
        
        if (newType === 'playlists' || newType === 'profiles') {
            setFilterIsLoading(true);
            try {
                let searchResults;
                if (newType === 'playlists') {
                    const playlists = await searchPlaylists('');
                    searchResults = { results: playlists };
                } else {
                    const profiles = await searchProfiles('');
                    searchResults = profiles;
                }
                setSearchFilmsResponse(searchResults.results);
                setScene('SEARCH_RESULTS');
            } catch (error) {
                console.error(`Error fetching ${newType}:`, error);
                toast({
                    title: "Error",
                    description: `Failed to load ${newType}.`,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setFilterIsLoading(false);
            }
        } else {
            setScene('HOME');
        }
    };

    const toggleGenreVisibility = () => {
        setShowGenres(!showGenres);
        setSelectedGenres([]);
    }

    const handleGenreChange = (genre) => {
        setSelectedGenres(prevGenres => {
            const newGenres = prevGenres.includes(genre)
                ? prevGenres.filter(g => g !== genre)
                : [...prevGenres, genre];
            return newGenres;
        });
        setCurrentPage(1);
        setHasMore(true);
    };

    const renderGenreFilters = () => (
        <VStack spacing={2} width={'100%'} pb=''>
          <Button 
            alignSelf='start'
            onClick={toggleGenreVisibility} 
            h='20px'
            fontSize='10px'
            mx='7'
            px='8px'
            colorScheme="purple"
            isDisabled={searchType !== 'films'}
          >
            {showGenres ? "Hide Genres" : "Genres"}
          </Button>
          {showGenres && searchType === 'films' && (
            <Flex flexWrap="wrap" justifyContent="center" alignContent="space-between" >
            {genres.map(genre => (
                <Button
                key={genre.id}
                h='20px'
                fontSize='10px'
                px='8px'
                m='1'
                variant={selectedGenres.includes(genre.id) ? "solid" : "outline"}
                bg={selectedGenres.includes(genre.id) ? "purple.600" : ""}
                onClick={() => handleGenreChange(genre.id)}
                >
                {genre.name}
                </Button>
            ))}
            </Flex>
          )}
        </VStack>
    );
    

    const callLogOut = () => {
        try {
            handleLogout();
            setScene('HOME');
        } catch (e) {
            console.log("DED: ", e);
        }
    }

    const renderLoginButton = (loggedIn) => {
        if (!loggedIn) {
            return (
                <MenuItem 
                    letterSpacing='wider' 
                    variant='ghost' 
                    fontFamily="buttonFont" 
                    onClick={() => navigate('/login')}
                >
                    Login
                </MenuItem>
            );
        } else {
            return (
                <MenuItem
                    letterSpacing='wider'
                    variant='ghost'
                    fontFamily="buttonFont"
                    onClick={() => {
                        navigate(`/profile/${userId}`);
                    }}
                >
                    Profile
                </MenuItem>
            );
        }
    }


    return (
        <>

            <HStack marginTop={'12px'} justifyContent='space-between' width={'100%'}  >
                <VStack >
                    <Button h={'100%'} w={'100%'} variant='ghost' 
                        onClick={() => {onHomeClick()}} >
                            <VStack align='start' spacing='.1px'>
                            <Heading colorScheme ='qTurquoise' variant='custom-tq' fontFamily ="qFont" fontSize='5xl' letterSpacing='tighter'>
                            QUARTILE
                            </Heading>
                            <Heading paddingLeft='8px' fontFamily="categoryFont" fontSize='xs' letterSpacing="widest" paddingRight={'12'}>
                            Film Rating System 
                            </Heading>
                            </VStack> 
                    </Button>
                </VStack>
                   
                <VStack paddingLeft='16'paddingBottom={'6'}>
                    <Menu isLazy>
                        <MenuButton
                            as={IconButton}
                            aria-label='Options'
                            icon={useColorModeValue(<CustomMenuIconBlack/>, <CustomMenuIcon/>)}
                            variant='ghost'
                        /> 

                        <MenuList
                            style={{
                                backgroundColor: '#6c39ac',
                                borderColor: 'white',
                            }} 
                        >
                            {renderLoginButton(loggedIn)}

                            <MenuItem
                                // width='100%' 
                                letterSpacing='wider' 
                                variant='ghost' 
                                fontFamily="buttonFont" 
                                onClick={() => {navigate('/about')}}
                            >
                                About
                            </MenuItem>

                            <MenuItem> 
                                <CustomSwitch
                                onColor={'#000'}
                                offColor={'#FFF'}
                                isChecked={customSwitchChecked}
                                handleSwitch={() =>{
                                    setCustomSwitchChecked(!customSwitchChecked);
                                    toggleColorMode();
                                }}
                                >
                                </CustomSwitch>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </VStack>  
            </HStack>

            <Box width={'88%'} pt='5'>
            <form onSubmit={onSearch} >
                       <Input 
                       value={userSearchInput}
                       variant="filled" 
                       placeholder={`search ${searchType}`}
                       _placeholder={{ color: '#94B0B3' }}
                       textAlign='center'
                       width='100%'
                       onChange={onChangeKeyword}
                       onSubmit={onSearch}
                       style={{
                           backgroundColor: '#6c39ac',
                           borderColor: '#6c39ac',
                           borderRadius: '12px',
                       }} 
                       />
            </form>
            </Box>
            <HStack spacing={1}>
                <Text
                    cursor="pointer"
                    onClick={() => handleSearchTypeToggle('films')}
                    fontWeight={searchType === 'films' ? 'bold' : 'normal'}
                    color={searchType === 'films' ? useColorModeValue("purple.500", "purple.500") : useColorModeValue("black", "white")}
                    fontSize="sm"
                >
                    films
                </Text>
                <Text>/</Text>
                <Text
                    cursor="pointer"
                    onClick={() => handleSearchTypeToggle('playlists')}
                    fontWeight={searchType === 'playlists' ? 'bold' : 'normal'}
                    color={searchType === 'playlists' ? useColorModeValue("purple.500", "purple.500") : useColorModeValue("black", "white")}
                    fontSize="sm"
                >
                    playlists
                </Text>
                <Text>/</Text>
                <Text
                    cursor="pointer"
                    onClick={() => handleSearchTypeToggle('profiles')}
                    fontWeight={searchType === 'profiles' ? 'bold' : 'normal'}
                    color={searchType === 'profiles' ? useColorModeValue("pink.400", "pink.400") : useColorModeValue("black", "white")}
                    fontSize="sm"
                >
                    profiles
                </Text>
            </HStack>
            
            {CategoryCheckbox(setCategories, homeClicked, setHomeClicked, searchType === 'films')}
            {searchType === 'films' && renderGenreFilters()}
        </>
    );
};

export default MobileTopNav;
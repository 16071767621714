import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Button,
  VStack,
  Input,
  useColorModeValue,
  useToast,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { createPlaylist } from '../api';

const CreatePlaylistModal = ({ userId, onPlaylistCreated }) => {
  const [newPlaylistTitle, setNewPlaylistTitle] = useState('');
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'black');
  const borderColor = useColorModeValue("black", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCreatePlaylist = async () => {
    const newPlaylist = {
      user_id: userId,
      playlistTitle: newPlaylistTitle,
      playlistFilms: [],
    };

    try {
      await createPlaylist(newPlaylist);
      
      toast({
        title: 'Playlist Created',
        description: `The new ${newPlaylistTitle} playlist has been created.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setNewPlaylistTitle('');
      onClose();
      
      // Notify parent component to refresh playlists
      if (onPlaylistCreated) {
        onPlaylistCreated();
      }

    } catch (error) {
      toast({
        title: 'Error',
        description: `There was an error creating the new playlist: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error creating new playlist:', error);
    }
  };

  return (
    <>
      <Button 
        onClick={onOpen}
        colorScheme="qTurquoise"
        size="xs"
      >
        Create Playlist
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay 
          bg='none'
          backdropFilter='auto'
          backdropInvert='80%'
          backdropBlur='2px'
        />
        <ModalContent bg={bgColor} borderColor={borderColor} borderWidth="3px" borderRadius='15px'>
          <ModalHeader>
            <Heading letterSpacing='wider' fontFamily='categoryFont' size={'md'}>
              CREATE PLAYLIST
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="stretch" spacing={4}>
              <Input
                placeholder="Playlist Title"
                value={newPlaylistTitle}
                onChange={(e) => setNewPlaylistTitle(e.target.value)}
              />
              <Button
                onClick={handleCreatePlaylist}
                disabled={!newPlaylistTitle.trim()}
                colorScheme="qPurple"
              >
                Create Playlist
              </Button>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent='center' mb='6'>
            <Button colorScheme="qPurple" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CreatePlaylistModal; 
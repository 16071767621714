import React, { useState, useEffect } from 'react';
import { useMedia } from 'react-use';
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Button,
    Box,
    VStack,
    Center,
    Heading,
    useToast,
    Text,
    InputGroup,
    InputRightElement,
    IconButton,
} from '@chakra-ui/react';
import { registerApi } from '../../api';
import zxcvbn from 'zxcvbn';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const RegisterScene = (props) => {

    const {
        setScene,
    } = props;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [fullName, setFullName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(null);
    const [usernameError, setUsernameError] = useState('');
    const [fullNameError, setFullNameError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const toast = useToast();
    const isMobile = useMedia('(max-width: 767px)');

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const handleEmailInputChange = (e) => setEmail(e.target.value);
    const handlePasswordInputChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        if (newPassword === '') {
            setPasswordStrength(null);
        } else {
            const result = zxcvbn(newPassword);
            setPasswordStrength(result);
        }
    };

    const handleUsernameChange = (e) => {
        const username = e.target.value;
        setUsername(username);
        validateUsername(username);
      };

    const handleFullNameChange = (e) => {
        const fullName = e.target.value;
        setFullName(fullName);
        validateFullName(fullName);
      };

    const validateUsername = (username) => {
    if (username.length === 0) {
        setUsernameError('');
    } else if (username.length < 3 || username.length > 20) {
        setUsernameError('username must be between 3 and 20 characters');
    } else {
        setUsernameError('');
    }
    };

    const validateFullName = (fullName) => {
        if (fullName.length === 0) {
            setFullNameError('');
        } else if (fullName.length < 3 || fullName.length > 20) {
            setFullNameError('full name must be between 3 and 20 characters');
        } else {
            setFullNameError('');
        }
    };

    // Email validation function
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isEmailError = email.length > 4 && !isValidEmail(email);
    const isPasswordWeak = passwordStrength && passwordStrength.score < 2;
    
    const isFormValid = !isEmailError && !isPasswordWeak && !usernameError && !fullNameError && email && username && password && fullName;

    //REGISTER
    const handleRegister = async () => {
        if (isPasswordWeak) {
            toast({
                title: 'Weak Password',
                description: "Please choose a stronger password",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            setIsLoading(true);
            const registerResult = await registerApi({ email, password, username, fullName });
            setIsLoading(false);
            toast({
                title: 'Registered Account',
                description: "You've created an account under " + email + " Verify Through Your Email to Log In ",
                status: 'warning',
                duration: 9000,
                isClosable: true,
            })
            setScene('VERIFY')
        }
        catch (e) {
            setIsLoading(false);
            toast({
                title: 'Registration Failure',
                description: e.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            console.log('ERROR registering account in RegisterScene: ', e);
        }
    }

    const renderPasswordStrength = () => {
        if (!password || !passwordStrength) return null;

        const crackTime = passwordStrength.crack_times_display.offline_slow_hashing_1e4_per_second;
        const score = passwordStrength.score;

        let color;
        switch (score) {
            case 0:
            case 1:
                color = 'red.500';
                break;
            case 2:
                color = 'orange.500';
                break;
            case 3:
                color = 'yellow.500';
                break;
            case 4:
                color = 'green.500';
                break;
            default:
                color = 'gray.500';
        }

        return (
            <Text color={color} fontSize="sm" mt={1} textAlign="left" >
                Time to hack: {crackTime}
            </Text>
        );
    };

    return (
        <VStack  width ="100%" align='stretch'>
            <Center>
                <VStack p={['','72px']} width='100%' justify= 'center'   >
                    {!isMobile ? (
                        <Heading m={'-2'}  variant='custom-tq' fontFamily={"qFont"}  >
                            QUARTILE
                        </Heading>
                        ) : null} 
                    {!isMobile ? (
                        <VStack p='15' width='50%' justify= 'center'>
                            <FormControl isInvalid={isEmailError}>
                                <FormLabel letterSpacing='wider'  htmlFor='email'> Email </FormLabel>
                                <Input
                                    id='email'
                                    type='email'
                                    value={email}
                                    onChange={handleEmailInputChange}
                                />
                                {!isEmailError ? (
                                    <FormHelperText>
                                    </FormHelperText>
                                ) : (
                                    <FormErrorMessage> enter a valid email address </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl isInvalid={usernameError}>
                                <FormLabel letterSpacing='wider' htmlFor='username'> Username </FormLabel>
                                <Input
                                    id='username'
                                    type='username'
                                    value={username}
                                    onChange={handleUsernameChange}
                                />
                                {!usernameError ? (
                                    <FormHelperText>
                                    </FormHelperText>
                                ) : (
                                    <FormErrorMessage> {usernameError} </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl isInvalid={fullNameError}>
                                <FormLabel letterSpacing='wider' htmlFor='fullName'> Full Name </FormLabel>
                                <Input
                                    id='fullName'
                                    type='fullName'
                                    value={fullName}
                                    onChange={handleFullNameChange}
                                />
                                {!fullNameError ? (
                                    <FormHelperText>
                                    </FormHelperText>
                                ) : (
                                    <FormErrorMessage> {fullNameError} </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl isInvalid={isPasswordWeak}>
                                <FormLabel letterSpacing='wider' htmlFor='password'> Password </FormLabel>
                                <InputGroup>
                                    <Input
                                        id='password'
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={handlePasswordInputChange}
                                    />
                                    <InputRightElement>
                                        <IconButton
                                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                                            icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                                            onClick={togglePasswordVisibility}
                                            variant="ghost"
                                        />
                                    </InputRightElement>
                                </InputGroup>
                                <FormHelperText>
                                    {renderPasswordStrength()}
                                    {isPasswordWeak && (
                                <FormErrorMessage>Please choose a stronger password.</FormErrorMessage>
                            )}
                                </FormHelperText>
                            </FormControl>
                            
                            {/* <FormControl isInvalid={isPasswordWeak}>
                                <FormLabel letterSpacing='wider' htmlFor='password'> Password </FormLabel>
                                <Input
                                    id='password'
                                    type='password'
                                    value={password}
                                    onChange={handlePasswordInputChange}
                                />
                                <FormHelperText>
                                    {renderPasswordStrength()}
                                </FormHelperText>
                            </FormControl> */}

                            <Box p={'2'} >
                                <Button
                                    letterSpacing='wider'
                                    isLoading={isLoading}
                                    // colorScheme='brand'
                                    colorScheme='qPurple'
                                    width ='120px'
                                    // colorScheme='red'
                                    onClick={handleRegister}
                                    isDisabled={!isFormValid}
                                >
                                    Sign Up
                                </Button>
                            </Box>
                        </VStack>
                        ) : 
                        <VStack paddingTop='5' width='80%' justify= 'center' spacing='0.25'>
                            <FormControl isInvalid={isEmailError}>
                                <FormLabel letterSpacing='wider'  htmlFor='email'> Email </FormLabel>
                                <Input
                                    id='email'
                                    type='email'
                                    value={email}
                                    onChange={handleEmailInputChange}
                                />
                                {!isEmailError ? (
                                    <FormHelperText>
                                    </FormHelperText>
                                ) : (
                                    <FormErrorMessage> Email is required. </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl isInvalid={usernameError}>
                                <FormLabel letterSpacing='wider' htmlFor='username'> Username </FormLabel>
                                <Input
                                    id='username'
                                    type='username'
                                    value={username}
                                    onChange={handleUsernameChange}
                                />
                                {!usernameError ? (
                                    <FormHelperText>
                                    </FormHelperText>
                                ) : (
                                    <FormErrorMessage> {usernameError} </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl isInvalid={fullNameError}>
                                <FormLabel letterSpacing='wider' htmlFor='fullName'> Full Name </FormLabel>
                                <Input
                                    id='fullName'
                                    type='fullName'
                                    value={fullName}
                                    onChange={handleFullNameChange}
                                />
                                {!fullNameError ? (
                                    <FormHelperText>
                                    </FormHelperText>
                                ) : (
                                    <FormErrorMessage> {fullNameError} </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl isInvalid={isPasswordWeak}>
                                <FormLabel letterSpacing='wider' htmlFor='password'> Password </FormLabel>
                                <InputGroup>
                                    <Input
                                        id='password'
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={handlePasswordInputChange}
                                    />
                                    <InputRightElement>
                                        <IconButton
                                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                                            icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                                            onClick={togglePasswordVisibility}
                                            variant="ghost"
                                        />
                                    </InputRightElement>
                                </InputGroup>
                                <FormHelperText>
                                    {renderPasswordStrength()}
                                </FormHelperText>
                            </FormControl>

                            <Box width='100%' paddingTop={'8'} >
                                <Button
                                    letterSpacing='wider'
                                    isLoading={isLoading}
                                    // colorScheme='brand'
                                    colorScheme='qPurple'
                                    width ='120px'
                                    // colorScheme='red'
                                    onClick={handleRegister}
                                    isDisabled={!isFormValid}
                                >
                                    Sign Up
                                </Button>
                            </Box>
                        </VStack>
                        } 
                    </VStack>
            </Center>
        </VStack>
    );
};

export default RegisterScene;
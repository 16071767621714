import React from 'react';
import {
    Box,
    VStack,
    Text,
    HStack,
    useColorModeValue,
    IconButton,
    Heading,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';

const MobileActivity = ({ notifications, onNotificationClick, onClose }) => {
    const navigate = useNavigate();
    const bgColor = useColorModeValue('white', 'black');
    const textColor = useColorModeValue('gray.800', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.200');
    const hoverBg = useColorModeValue('gray.50', 'whiteAlpha.100');
    const unreadBg = 'qTurquoise.500';
    const timestampColor = useColorModeValue('gray.600', 'gray.600');

    const getNotificationText = (notification) => {
        const senderName = notification.sender.username;
        
        if (notification.type === 'USER_FOLLOW') {
            return (
                <Text fontSize="sm" color={textColor}>
                    <Text as="span" color="pink.400">@{senderName}</Text> followed you
                </Text>
            );
        } else if (notification.type === 'PLAYLIST_FOLLOW') {
            return (
                <Text fontSize="sm" color={textColor}>
                    <Text as="span" color="pink.400">@{senderName}</Text> followed your playlist{' '}
                    <Text as="span" color="purple.500">{notification.playlistId.playlistTitle}</Text>
                </Text>
            );
        }
        return '';
    };

    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const diffTime = Math.abs(now - date);
        const diffMinutes = Math.floor(diffTime / (1000 * 60));
        const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 0) {
            return `${diffDays}d ago`;
        } else if (diffHours > 0) {
            return `${diffHours}h ago`;
        } else if (diffMinutes > 0) {
            return `${diffMinutes}m ago`;
        } else {
            return 'Just now';
        }
    };

    return (
        <VStack 
            align="stretch" 
            spacing={0} 
            bg={bgColor}
            height="100%"
            width="100%"
        >
            <HStack 
                justify="space-between" 
                align="center" 
                px={4} 
                py={3}
                borderBottom="1px"
                borderColor={borderColor}
                bg="purple.500"
            >
                <Heading 
                    size="md" 
                    color="white"
                    fontFamily="buttonFont"
                    letterSpacing="wider"
                >
                    Activity
                </Heading>
                <IconButton
                    icon={<IoClose size="24px" />}
                    variant="ghost"
                    color="white"
                    _hover={{ bg: 'whiteAlpha.200' }}
                    onClick={onClose}
                    aria-label="Close activity"
                />
            </HStack>

            <Box overflowY="auto" height="calc(100% - 60px)">
                {notifications.length === 0 ? (
                    <Text p={4} textAlign="center" color={textColor}>
                        No activity
                    </Text>
                ) : (
                    notifications
                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                        .map((notification) => (
                            <Box
                                key={notification._id}
                                p={3}
                                bg={notification.read ? 'transparent' : unreadBg}
                                _hover={{ bg: hoverBg }}
                                cursor="pointer"
                                onClick={() => onNotificationClick(notification)}
                                borderBottom="1px"
                                borderColor={borderColor}
                            >
                                <VStack align="stretch" spacing={2}>
                                    <HStack spacing={3} justify="space-between" align="center">
                                        <Box flex={1}>
                                            {getNotificationText(notification)}
                                        </Box>
                                        <Text fontSize="xs" color={timestampColor}>
                                            {getFormattedDate(notification.createdAt)}
                                        </Text>
                                    </HStack>
                                </VStack>
                            </Box>
                        ))
                )}
            </Box>
        </VStack>
    );
};

export default MobileActivity; 
import React, { useState, useEffect } from 'react';
import {
    HStack,
    VStack,
    Button,
    chakra,
    Image,
    useCheckbox,
    Text,
    useCheckboxGroup,
    ColorMode,
    useColorModeValue,
    Input,
    useToast,
    Tooltip,
    Icon,
    Flex,
    Box
} from '@chakra-ui/react';
import {searchTmdbFilms, searchProfiles, searchPlaylists, searchTmdbFilmById, fetchGenres, getTopFilmsByCategoryAndGenre, getFilmsByGenres} from '../api';
import CustomSwitch from "./CustomSwitch";
import { useNavigate } from 'react-router-dom';
import NotificationPopover from './NotificationPopover';


function CustomCheckbox(props) {
    const { state, getInputProps, getLabelProps, htmlProps } =
        useCheckbox(props);
    const textColor = useColorModeValue("black", "white");
    const borderColorUnselected = useColorModeValue("white", "black");
    const disabledColor = useColorModeValue("gray.300", "gray.600");

    const commonStyles = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gridColumnGap: 2,
        bg: 'black.50',
        border: '3px solid',
        rounded: 'lg',
        px: 3,
        py: 1,
        cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        opacity: props.isDisabled ? 0.6 : 1,
    };

    if (state.isChecked) {
        return (
            <chakra.label
                {...commonStyles}
                borderColor='qPurple.500'
                {...htmlProps}
            >
                <input {...getInputProps()} hidden />
                <Text
                    color={props.isDisabled ? disabledColor : textColor}
                    fontFamily='categoryFont'
                    letterSpacing="widest"
                    fontWeight='semibold'
                    {...getLabelProps()}
                    fontSize={['xs','md']}
                >    
                    {props.value}
                </Text>
            </chakra.label>
        )
    }
    return (
        <chakra.label
            {...commonStyles}
            borderColor={borderColorUnselected}
            variant={['mobile','baseStyle']}
            {...htmlProps}
        >
            <input {...getInputProps()} hidden />
            <Text
                color={props.isDisabled ? disabledColor : textColor}
                fontFamily='categoryFont'
                letterSpacing="widest"
                fontWeight='semibold'
                {...getLabelProps()}
                fontSize={['xs','md']}
            >
                {props.value}
            </Text>
        </chakra.label>
    )
}


const categories = [
  { value: 'Top Plot', tooltip: null },
  { value: 'Top Acting', tooltip: "Top Narration For Documentaries" },
  { value: 'Top Cinematography', tooltip: null },
  { value: 'Top Novelty', tooltip: null },
  { value: 'Top Ending', tooltip: null }
];

function TooltipWrapper({ children, tooltip }) {
    if (!tooltip) return children;

    return (
        <Tooltip 
            hasArrow 
            label={tooltip}
            placement='top' 
            bg='qTurquoise.500' 
            fontSize='16px'
            borderRadius='md'
        >
            <Box display="inline-block">
                {children}
            </Box>
        </Tooltip>
    );
}

function CategoryCheckbox(setCategories, homeClicked, setHomeClicked, searchType) {
    const { value, getCheckboxProps, setValue } = useCheckboxGroup({});

    React.useEffect(() => {
        setCategories(value);
    }, [value]);

    React.useEffect(() => {
        setValue([]);
        setHomeClicked(false);
    }, [homeClicked]);

    return (
        <HStack width='100%' justifyContent={['space-between', 'space-around']} paddingBottom='4'>
            {categories.map((category) => (
                <TooltipWrapper key={category.value} tooltip={category.tooltip}>
                    <CustomCheckbox 
                        {...getCheckboxProps({ value: category.value })}
                        isDisabled={searchType !== 'films'}
                    />
                </TooltipWrapper>
            ))}
        </HStack>
    );
}

// ****
// original custom checkbox display, make sure to keep this for backup in case tooltip implementation breaks above  
// function CategoryCheckbox(setCategories, homeClicked, setHomeClicked, searchType) {
//     const { value, getCheckboxProps, setValue } = useCheckboxGroup({});

//     React.useEffect(() => {
//         setCategories(value);
//     }, [value]);

//     React.useEffect(() => {
//         setValue([]);
//         setHomeClicked(false);
//     }, [homeClicked]);

//     return (
//         <HStack width='100%' justifyContent={['space-between', 'space-around']} paddingBottom='4' >
//             {['Top Plot', 'Top Acting', 'Top Cinematography', 'Top Novelty', 'Top Ending'].map((category) => (
//                 <CustomCheckbox 
//                     key={category}
//                     {...getCheckboxProps({ value: category })}
//                     isDisabled={searchType !== 'films'}
//                 />
//             ))}
//         </HStack>
//     )
// }

const TopNav = (props) => {

    const {
        setScene,
        loggedIn,
        handleLogout,
        setSearchFilmsResponse,
        searchFilmsResponse,
        // setAllResults,
        // allResults,
        customSwitchChecked,
        setCustomSwitchChecked,
        toggleColorMode,
        setFilterIsLoading,
        searchType,
        setSearchType,
        selectedGenres,
        setSelectedGenres,
        userId
    } = props;

    const [userSearchInput, setUserSearchInput] = useState('');
    const [categories, setCategories] = useState([]);
    const [homeClicked, setHomeClicked] = useState(false);
    const [genres, setGenres] = useState([]);
    const [showGenres, setShowGenres] = useState(false);
    // const [selectedGenres, setSelectedGenres] = useState([]);
    const toast = useToast();
    const [toastDisplayed, setToastDisplayed] = useState(false);
    // const [searchType, setSearchType] = useState('films');
    const navigate = useNavigate();

    // LIGHT/DARK UI declarations
    // const value = useColorModeValue(lightModeValue, darkModeValue)
    // const borderBottomSearch = useColorModeValue('blue', 'red.200');
    // size is working based on this system, something about border will not let it get implimented, also possible to do
    //a variation of this through the theme.js file
    // const size = useColorModeValue('sm', 'lg');

    // const handleSearchTypeToggle = () => {
    //     setSearchType(prevType => prevType === 'films' ? 'profiles' : 'films');
    //     console.log('searchType', searchType);
    // };

   
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true); // Track initial load

    
    const fetchResults = async (page, clearResults = false) => {
        if (loading) return; // Prevent fetching if already loading
        setLoading(true);
        try {
            const newResults = await getFilmsByGenres(selectedGenres, page);
            // console.log('Fetched results for page', page, newResults); // Log the results
            
            // Clear previous results if specified
            if (clearResults) {
                setSearchFilmsResponse(newResults);
                // setAllResults(newResults); // Set new results directly
            } else {
                // Append new results to allResults, avoiding duplicates
                const uniqueResults = newResults.filter(newItem => 
                    // !allResults.some(existingItem => existingItem.id === newItem.id)
                    !searchFilmsResponse.some(existingItem => existingItem.id === newItem.id)
                );
                // setAllResults((prevResults) => [...prevResults, ...uniqueResults]);
                setSearchFilmsResponse((prevResults) => [...prevResults, ...uniqueResults]);                
                // console.log('kai test allResults', allResults);
            }
            setHasMore(newResults.length > 0); // Check if there are more films
        } catch (error) {
            console.error('Error fetching films:', error);
        } finally {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     // Update displayResults when results prop changes
    //     if (results?.results) {
    //         setAllResults(results.results);
    //     } else {
    //         // setAllResults(allResults);
    //     }
    // }, [results]);

    useEffect(() => {
        // Reset results and page when genres change
        // console.log('Genres changed:', selectedGenres);
        // setAllResults([]);
        setSearchFilmsResponse([]);
        setCurrentPage(1);

        // Only fetch results if it's not the initial load
        if (!isInitialLoad && categories.length === 0) {
            fetchResults(1, true); // Fetch results for the first page and clear previous results
        } else {
            setIsInitialLoad(false); // Set to false after the first load
        }
    }, [selectedGenres]);

    useEffect(() => {
        // Only fetch results if there are selected genres
        if (selectedGenres.length > 0 && categories.length === 0) {
            fetchResults(currentPage);
        }
    }, [currentPage]);

    // Debounce function
    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    };

    const handleScroll = () => {
        if (loading) return; // Prevent fetching if already loading
        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200 && hasMore) {
            setCurrentPage((prevPage) => prevPage + 1); // Increment the page number
        }
    };
    
    // Debounce the scroll event
    useEffect(() => {
        const debouncedHandleScroll = debounce(handleScroll, 200); // Adjust the delay as needed
        window.addEventListener('scroll', debouncedHandleScroll);
        return () => window.removeEventListener('scroll', debouncedHandleScroll);
    }, [hasMore, loading]);
    // end of test code

    const onChangeKeyword = (event) => {
        setUserSearchInput(event.target.value);
    };

    const onSearch = async (event) => {
        event.preventDefault();
        setFilterIsLoading(true);
        try {
            let searchResults;
            if (searchType === 'films') {
                if (!userSearchInput.trim()) {
                    toast({
                        title: "Empty Film Search",
                        description: "Please enter a search term to find films.",
                        status: "warning",
                        duration: 3000,
                        isClosable: true,
                    });
                    setFilterIsLoading(false);
                    return;
                }
                const films = await searchTmdbFilms(userSearchInput);
                searchResults = films;
            } else if (searchType === 'playlists') {
                const playlists = await searchPlaylists(userSearchInput);
                console.log('Playlist search results:', playlists);  // Add this line
                searchResults = { results: playlists };  // Wrap playlists in results object
            } else if (searchType === 'profiles') {
                // For profiles, allow empty search
                const profiles = await searchProfiles(userSearchInput);
                searchResults = profiles;
            }
            setSearchFilmsResponse(searchResults.results);
            // setAllResults(searchResults.results);
            // console.log('kai test allResults from topNav', allResults);
            setScene('SEARCH_RESULTS');
        } catch (error) {
            console.error('Search error:', error);
            toast({
                title: "Search Error",
                description: "An error occurred while searching. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setFilterIsLoading(false);
        }
    };
   
    const onHomeClick = () => {
        document.querySelector("input").value = "";
        setCategories([]);
        setUserSearchInput('');
        setSelectedGenres([]);
        // setAllResults([]);
        setSearchFilmsResponse([]);
        setScene('HOME');
        setSearchType('films');
        setHomeClicked(true);
        setToastDisplayed(false);
        setShowGenres(false); 
    }

    // way i was testing to get beta stagin to work
    // useEffect(() => {
    //     const fetchGenreData = async () => {
    //         try {
    //             const genreData = await fetchGenres();
    //             if (genreData && Array.isArray(genreData.genres)) {
    //                 setGenres(genreData.genres);
    //             } else {
    //                 console.error("Invalid genre data received:", genreData);
    //                 setGenres([]);
    //             }
    //         } catch (error) {
    //             console.error("Error fetching genres:", error);
    //             setGenres([]);
    //         }
    //     };
    //     fetchGenreData();
    // }, []);

    useEffect(() => {
        const fetchGenreData = async () => {
            try {
                const genreData = await fetchGenres();
                setGenres(genreData.genres);
            } catch (error) {
                console.error("Error fetching genres:", error);
            }
        };
        fetchGenreData();
    }, []);

    useEffect(() => {
        const onCategoryAndGenreFilter = async () => {
            try {
                document.querySelector("input").value = "";
                setFilterIsLoading(true);
                
                if (categories.length === 0 && selectedGenres.length === 0) {
                    setScene('HOME');
                    setSearchFilmsResponse([]);
                    // setAllResults([]);
                } else if (selectedGenres.length > 0 && categories.length === 0) {
                    // When only genres are selected, fetch from TMDB
                    const films = await getFilmsByGenres(selectedGenres);
                    let searchResults = {
                        results: films
                    };
                    setSearchFilmsResponse(searchResults.results);
                    // setAllResults(searchResults.results);
                    // console.log('kai test allResults genre from topNav', allResults);
                    // also the setSearchFilmsResponse was the initial way we were handling the results from the api call but now 
                    // i'm setting everything as allResults to be passed to the SearchResultsScene, need to go back and trim out the searchFilmsResponse
                    // or update the setAllResults value to be the searchFilmsResponse.results instead of just the allResults
                    // i'm not even sure this else if is doing that much here or is being redundant from the logic above
                    setScene('SEARCH_RESULTS', { selectedGenres }); // Pass selectedGenres here
                    
                } else {
                    // When both categories and genres are selected, fetch from quartile database
                    
                    const films = await getTopFilmsByCategoryAndGenre(categories, selectedGenres);
                    let searchResults = {
                        results: films
                    };
                    setSearchFilmsResponse(searchResults.results);
                    // setAllResults(searchResults.results);
                    setScene('SEARCH_RESULTS', { selectedGenres }); // Pass selectedGenres here
                    if (!toastDisplayed) {
                        toast({
                            title: "Top Category Filters",
                            description:
                            "Display films rated (Well Above Average - 4) in the selected categories. Top Novelty is my favorite.",
                            status: "success",
                            duration: 12000,
                            isClosable: true
                        });
                        
                        setToastDisplayed(true);
                    }
                }
            } catch (e) {
                console.error("Error in category and genre filtering:", e);
            } finally {
                setFilterIsLoading(false);
            }
        };
        
        onCategoryAndGenreFilter();
    }, [categories, selectedGenres]);


    // useEffect(() => {
    //     const onCategoryAndGenreFilter = async () => {
    //         try {
    //             document.querySelector("input").value = "";
    //             setFilterIsLoading(true);
                
    //             if (categories.length === 0 && selectedGenres.length === 0) {
    //                 setScene('HOME');
    //                 setSearchFilmsResponse([]);
    //             } else {
    //                 setScene('SEARCH_RESULTS');
    //                 const films = await getTopFilmsByCategoryAndGenre(categories, selectedGenres);
    //                 let searchResults = {
    //                     results: films
    //                 };
    //                 setSearchFilmsResponse(searchResults);
                    
    //                 if (!toastDisplayed) {
    //                     toast({
    //                         title: "Top Category Filters",
    //                         description:
    //                         "display films rated ( Well Above Average - 4 ) in the selected categories. Top Novelty is my favorite.",
    //                         status: "success",
    //                         duration: 12000,
    //                         isClosable: true
    //                     });
                        
    //                     setToastDisplayed(true);
    //                 }
    //             }
    //         } catch (e) {
    //             console.error("Error in category and genre filtering:", e);
    //         } finally {
    //             setFilterIsLoading(false);
    //         }
    //     };
        
    //     onCategoryAndGenreFilter();
    // }, [categories, selectedGenres]);
    
    const callLogOut = () => {
        try {
            handleLogout();
            navigate('/');
            // setScene('HOME');
        } catch (e) {
            console.log("DED: ", e);
        }
    }

    const renderLoginButton = (loggedIn) => {
        const navigate = useNavigate();
        const iconColor = useColorModeValue("black", "white");

        if (!loggedIn) {
            return (
                <Button
                    letterSpacing='wider'
                    variant='ghost'
                    fontFamily="buttonFont"
                    onClick={() => {
                        navigate('/login');
                    }}
                > Login </Button>
            );
        } else {
            return (
                <Button
                    letterSpacing='wider'
                    variant='ghost'
                    fontFamily="buttonFont"
                    onClick={() => {
                        navigate(`/profile/${userId}`);
                    }}
                >
                    Profile
                </Button>
            );
        }
    }

    const renderNotificationButton = (loggedIn) => {
        if (loggedIn) {
            return <NotificationPopover />;
        }
        return null;
    }

    const handleSearchTypeToggle = async (newType) => {
        setSearchType(newType);
        setUserSearchInput('');
        setSearchFilmsResponse([]);
        setShowGenres(false);
        
        if (newType === 'playlists' || newType === 'profiles') {
            setFilterIsLoading(true);
            try {
                let searchResults;
                if (newType === 'playlists') {
                    const playlists = await searchPlaylists('');
                    searchResults = { results: playlists };
                } else {
                    const profiles = await searchProfiles('');
                    searchResults = profiles;
                }
                setSearchFilmsResponse(searchResults.results);
                setScene('SEARCH_RESULTS');
            } catch (error) {
                console.error(`Error fetching ${newType}:`, error);
                toast({
                    title: "Error",
                    description: `Failed to load ${newType}.`,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setFilterIsLoading(false);
            }
        } else {
            setScene('HOME');
        }
    };

    const customSearchBar = () => {
        const textColor = useColorModeValue("black", "white");
        const selectedColor = useColorModeValue("purple.500", "purple.500");
        const selectedColorB = useColorModeValue("pink.400", "pink.400");
        const selectedBg = useColorModeValue("gray.200", "gray.700");
    
        return (
            <form onSubmit={onSearch}>           
                {/* <HStack pl='14'> */}
                <VStack pr='100px'>
                    <Input
                        value={userSearchInput}
                        className="search-txt"
                        focusBorderColor={textColor}
                        isInvalid
                        errorBorderColor={textColor}
                        alignSelf='center'
                        variant='flushed'
                        placeholder={`search ${searchType}`}
                        onChange={onChangeKeyword}
                        onSubmit={onSearch}
                        width='300px'
                    />
                    <HStack spacing={1}>
                        <Text
                            cursor="pointer"
                            onClick={() => handleSearchTypeToggle('films')}
                            fontWeight={searchType === 'films' ? 'bold' : 'normal'}
                            // bg={searchType === 'films' ? selectedBg : 'transparent'}
                            color={searchType === 'films' ? selectedColor : textColor}
                            p={1}
                            borderRadius="md"
                            fontSize="sm"
                        >
                            films
                        </Text>
                        <Text>/</Text>
                        <Text
                            cursor="pointer"
                            onClick={() => handleSearchTypeToggle('playlists')}
                            fontWeight={searchType === 'playlists' ? 'bold' : 'normal'}
                            // bg={searchType === 'films' ? selectedBg : 'transparent'}
                            color={searchType === 'playlists' ? selectedColor : textColor}
                            p={1}
                            borderRadius="md"
                            fontSize="sm"
                        >
                            playlists
                        </Text>
                        <Text>/</Text>
                        <Text
                            cursor="pointer"
                            onClick={() => handleSearchTypeToggle('profiles')}
                            fontWeight={searchType === 'profiles' ? 'bold' : 'normal'}
                            // bg={searchType === 'profiles' ? selectedBg : 'transparent'}
                            color={searchType === 'profiles' ? selectedColorB : textColor}
                            p={1}
                            borderRadius="md"
                            fontSize="sm"
                        >
                            profiles
                        </Text>
                    </HStack>
                    </VStack>
                {/* </HStack> */}
            </form>
        );
    };
// const customSearchBar = () => {
//     const textColor = useColorModeValue("black", "white");
  
//     return (
//         <form onSubmit={onSearch} style={{width: '33%'}} >           
//             <HStack>
//                 <Input
//                     className="search-txt"
//                     focusBorderColor= {textColor}
//                     // using is invalid to set the bottom border to white with the error border color, essentially the invalid
//                     // setting is set true by default since we aren't super worried about entering search wrong like an email
//                     // would be
//                     isInvalid
//                     errorBorderColor= {textColor}
//                     alignSelf='center'
//                     marginLeft={['0px','-104px']}
//                     // text-align= 'center'
//                     variant='flushed'
//                     placeholder='Search'
//                     onChange={onChangeKeyword}
//                     onSubmit={onSearch}
//                     width='50%'
//                 />
//                 <Button size="sm" onClick={handleSearchTypeToggle}>
//                         {searchType === 'films' ? 'Profiles' : 'Films'}
//                 </Button>
//             </HStack>
//         </form>
//     );
//   };

    const toggleGenreVisibility = () => {
        setShowGenres(!showGenres);
        setSelectedGenres([]);
    }

    const handleGenreChange = (genre) => {
        setSelectedGenres(prevGenres =>
            prevGenres.includes(genre)
                ? prevGenres.filter(g => g !== genre)
                : [...prevGenres, genre]
        );
    };

    const renderGenreFilters = () => (
        <HStack spacing={2} width={'88%'} pb='2' >
          <Button 
            onClick={toggleGenreVisibility} 
            // size="xs" 
            h='20px'
            fontSize='10px'
            px='8px'
            m='1'
            colorScheme="purple"
            isDisabled={searchType !== 'films'}
          >
            {showGenres ? "Hide Genres" : "Genres"}
          </Button>
          {showGenres && searchType === 'films' && (
            // <HStack spacing={1} wrap="wrap">
            //   {genres.map(genre => (
            //     <Button
            //       key={genre.id}
            //       h='20px'
            //       fontSize='10px'
            //       px='8px'
            //       variant={selectedGenres.includes(genre.id) ? "solid" : "outline"}
            //       bg={selectedGenres.includes(genre.id) ? "purple.600" : ""}
            //       onClick={() => handleGenreChange(genre.id)}
            //     >
            //       {genre.name}
            //     </Button>
            //   ))}
            // </HStack>
            <Flex flexWrap="wrap" justifyContent="center" alignContent="space-between" >
            {genres.map(genre => (
                <Button
                key={genre.id}
                h='20px'
                fontSize='10px'
                px='10px'
                m='1'
                variant={selectedGenres.includes(genre.id) ? "solid" : "outline"}
                bg={selectedGenres.includes(genre.id) ? "purple.600" : ""}
                onClick={() => handleGenreChange(genre.id)}
                >
                {genre.name}
                </Button>
            ))}
            </Flex>
          )}
        </HStack>
      );

    return (
        <>

            <HStack marginTop={'18px'} width={'100%'} justifyContent={"space-between"} >
                <VStack class= " alphaContainerdiv"  >
                    <Button h={'100%'} w={'100%'} variant='ghost' onClick={() => {onHomeClick()}} >
                        <Image
                            src='QLogoWeb.png'
                            w={['50px', '100px', '120px']}
                        />
                    </Button>
                </VStack>
                       
                {customSearchBar()}

                <HStack alignItems="start" spacing='-10px'>
                    <Box pt='2'>
                        {renderNotificationButton(loggedIn)}
                    </Box>
               
                <VStack  spacing={'-4px'} >
                    {renderLoginButton(loggedIn)}
                    <Button 
                        letterSpacing='wider' 
                        variant='ghost' 
                        fontFamily="buttonFont" 
                        onClick={() => {
                            navigate('/about');
                        }}
                    > 
                        About 
                    </Button>
                    <CustomSwitch
                        onColor={'#000'}
                        offColor={'#FFF'}
                        isChecked={customSwitchChecked}
                        handleSwitch={() =>{
                            setCustomSwitchChecked(!customSwitchChecked);
                            toggleColorMode();
                        }}
                    >
                    </CustomSwitch>
                </VStack>
                </HStack>
            </HStack>
            {CategoryCheckbox(setCategories, homeClicked, setHomeClicked, searchType)}
            {renderGenreFilters()}
        </>
    );
};

export default TopNav;
import React, { useState, useEffect, useCallback, useRef }  from 'react';
import { useMedia } from 'react-use';
import {
  Box,
  VStack,
  Link,
  Stack,
  HStack,
  Text,
  Image,
  Button,
  Center,
  SimpleGrid,
  Spinner,
  useColorModeValue,
  Heading,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import ProfileFilmCard from './ProfileFilmCard';
import AltNav from "./AltNav";
import MobileAltNav from './MobileAltNav';
import EditProfileModal from './EditProfileModal';
import CreatePlaylistModal from './CreatePlaylistModal';
import { getPlaylists, getFollowedPlaylists, getUserProfile, followUser, unfollowUser, deletePlaylist } from '../api';
import ProfileImage from './ProfileImage';
import ProfileCard from './ProfileCard';
import FollowButton from './FollowButton';
import { FaShare } from 'react-icons/fa';
import { useToast } from '@chakra-ui/react';
// import playlist from '../../../models/playlist';

const ProfilePage = (props) => {
    const {
      setLoggedIn,
      loggedIn,
      handleLogout,
      customSwitchChecked,
      setCustomSwitchChecked,
      colorMode,
      toggleColorMode,
      loggedInUserId
    } = props;

  const {userId} = useParams();
  const isMounted = useRef(true);
  const isMobile = useMedia('(max-width: 767px)');
  const [isLoading, setIsLoading] = useState(true);
  const [playlists, setPlaylists] = useState([]);
  const [followedPlaylists, setFollowedPlaylists] = useState([]);
  const [userProfile, setUserProfile] = useState([]);
  const [profilePicUpdateTrigger, setProfilePicUpdateTrigger] = useState(0);
  const [isFollowing, setIsFollowing] = useState(false);
  const [activeView, setActiveView] = useState('playlists');
  const borderColor = useColorModeValue("black", "white");
  const textColor = useColorModeValue("black", "white");
  const selectedColor = useColorModeValue("purple.500", "purple.500");
  const selectedColorB = useColorModeValue("pink.400", "pink.400");
  const selectedColorC = useColorModeValue("qTurquoise.500", "qTurquoise.500");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [playlistToDelete, setPlaylistToDelete] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();  


  const toast = useToast();

  useEffect(() => {
    isMounted.current = true;
    const fetchData = async () => {
      if (!isMounted.current) return;
      setIsLoading(true);
      try {
        const userData = await getUserProfile(userId);
        if (!isMounted.current) return;
        setUserProfile(userData.user);
        const playlistsData = await getPlaylists(userId);
        if (!isMounted.current) return;
        setPlaylists(playlistsData.playlists);
        const followedPlaylistsData = await getFollowedPlaylists(userId);
        if (!isMounted.current) return;
        setFollowedPlaylists(followedPlaylistsData.playlists);
      } catch (error) {
        if (!isMounted.current) return;
        console.error("Error fetching user data:", error);
        toast({
          title: "Error",
          description: "Failed to load user data.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        if (!isMounted.current) return;
        setIsLoading(false);
      }
    };
  
    fetchData();
    return () => {
      isMounted.current = false;
    };
  }, [userId, toast]);


  useEffect(() => {
    // Check if the logged-in user is following this profile
    if (!isMounted.current) return;
    if (userProfile && userProfile.followers && loggedInUserId) {
      const newIsFollowing = userProfile.followers.some(
        follower => follower._id === loggedInUserId || follower === loggedInUserId
      );
      if (!isMounted.current) return;
      setIsFollowing(newIsFollowing);
    }
  }, [userProfile, loggedInUserId]);

  const handleProfileUpdate = async (updatedProfile) => {
    if (!isMounted.current) return;
    setUserProfile(prevProfile => ({...prevProfile, ...updatedProfile}));
    if (updatedProfile.profilePicUpdated) {
      if (!isMounted.current) return;
      setProfilePicUpdateTrigger(prev => prev + 1);
    }
  };

  const handleFollowToggle = async () => {
    if (!isMounted.current) return;
    try {
      if (isFollowing) {
        await unfollowUser(userId);
        if (!isMounted.current) return;
        setIsFollowing(false);
      } else {
        await followUser(userId);
        if (!isMounted.current) return;
        setIsFollowing(true);
      }
      const updatedUserData = await getUserProfile(userId);
      if (!isMounted.current) return;
      setUserProfile(updatedUserData.user);
    } catch (error) {
      console.error("Error toggling follow:", error);
      // Add user feedback here, e.g., toast notification
    }
  };

  const handleShare = () => {
    if (!isMounted.current) return;
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      if (!isMounted.current) return;
      toast({
        title: "Link Copied!",
        description: "The link to this profile has been copied to your clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }, (err) => {
      if (!isMounted.current) return;
      console.error('Could not copy text: ', err);
      toast({
        title: "Error",
        description: "Failed to copy link. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  // const handleRemovePlaylist = async (filmId) => {
  //   try {
  //     await updatePlaylist(playlistId, { $pull: { playlistFilms: filmId } });
  //     setPlaylistFilms(prevFilms => prevFilms.filter(film => film._id !== filmId));
  //     toast({
  //       title: "Film removed",
  //       description: "The film has been removed from the playlist.",
  //       status: "success",
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //   } catch (error) {
  //     console.error('Error removing film from playlist:', error);
  //     toast({
  //       title: "Error",
  //       description: "Failed to remove film from playlist.",
  //       status: "error",
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //   }
  // };

  const handleDeletePlaylist = (playlistId) => {
    setIsDeleteAlertOpen(true);
    setPlaylistToDelete(playlistId);
  };

  const confirmDelete = async () => {
    if (!isMounted.current || !playlistToDelete) return;
    try {
      await deletePlaylist(playlistToDelete);
      if (!isMounted.current) return;
      // Update the local state to remove the deleted playlist
      setPlaylists(prevPlaylists => prevPlaylists.filter(playlist => playlist._id !== playlistToDelete));
      if (!isMounted.current) return;
      toast({
        title: 'Playlist Deleted',
        description: 'The playlist has been successfully deleted.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteAlertOpen(false);
      setPlaylistToDelete(null);
    } catch (error) {
      if (!isMounted.current) return;
      toast({
        title: 'Delete Failed',
        description: error.message || 'An error occurred while deleting the playlist.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };


  return (
    <>
              <Box width="100%" overflowX="hidden">
                <VStack  width = "100%" align='stretch' >
                    <Center >
                    {isMobile ? (
                            <MobileAltNav 
                                setLoggedIn={setLoggedIn}
                                loggedIn={loggedIn}
                                handleLogout={handleLogout}
                                customSwitchChecked={customSwitchChecked}
                                setCustomSwitchChecked={setCustomSwitchChecked}
                                colorMode={colorMode}
                                toggleColorMode={toggleColorMode}
                                loggedInUserId={loggedInUserId}
                                userId={userId}
                            />
                        ) : (
                            <AltNav 
                                setLoggedIn={setLoggedIn}
                                loggedIn={loggedIn}
                                handleLogout={handleLogout}
                                customSwitchChecked={customSwitchChecked}
                                setCustomSwitchChecked={setCustomSwitchChecked}
                                colorMode={colorMode}
                                toggleColorMode={toggleColorMode}
                                userId={userId}
                                loggedInUserId={loggedInUserId}
                            />
                        )}
                    </Center>
                </VStack>

                <VStack marginTop={isMobile ? '16' : '-26px'}>

                {/* Prof Page Header */}
                <VStack  spacing='1'>
                    <ProfileImage 
                    userId={userId} 
                    updateTrigger={profilePicUpdateTrigger}
                    size='150px'
                    />
                  <Box pt='1'>
                    <Heading class = "profileFullNameFormat" letterSpacing={'wide'}>
                          {userProfile?.fullName || 'Full Name'}
                    </Heading>
                  </Box>
                  
                  <HStack h='20px' width ='500px' align='center' justify= 'center' spacing={'50px'}>
                    <Heading class = "profileUsernameFormat" letterSpacing={'wide'} >
                        @{userProfile?.username || 'username'}
                    </Heading>
                    <Heading class={'profileUsernameFormat'}>
                        {userProfile.followers?.length || 0} Followers
                    </Heading>
                  </HStack>
                    
                  <VStack spacing={1}>
                      {loggedIn && userId !== loggedInUserId && (
                          <FollowButton 
                              isFollowing={isFollowing} 
                              handleFollowToggle={handleFollowToggle} 
                          />
                      )}
                      {loggedIn && userId === loggedInUserId && (
                          <EditProfileModal 
                              userId={userId} 
                              userProfile={userProfile} 
                              onProfileUpdate={handleProfileUpdate}
                              profilePicUpdateTrigger={profilePicUpdateTrigger}
                              setProfilePicUpdateTrigger={setProfilePicUpdateTrigger}
                              colorMode={colorMode}
                          />
                      )}
                      <Button
                        leftIcon={<FaShare />}
                        onClick={handleShare}
                        mt='4'
                        pr='-2'
                        size='xs'
                        colorScheme="pink"
                      >
                        {/* Share */}
                      </Button>
                  </VStack>
                    {/* blank button need to add the ability to open an editProfile popover, like the playlist popover, */}
                    {/* write a function here?  */}
                    {/* <Button m='4' size='xs' variant='outline'>
                      {/* /* need to conditionally display this button if the account is your account or not! */}
                      {/* Edit Profile */}
                    {/* </Button> */} 
                </VStack>

                <VStack align="start" width={'84%'}>
                  <HStack spacing={1} pt='4'>
                          <Text
                              cursor="pointer"
                              onClick={() => setActiveView('playlists')}
                              fontWeight={activeView === 'playlists' ? 'bold' : 'normal'}
                              color={activeView === 'playlists' ? selectedColor : textColor}
                              p={1}
                              borderRadius="md"
                              fontSize= {isMobile ? "xs" : "sm"}
                          >
                              playlists
                          </Text>
                          <Text>/</Text>
                          <Text
                            cursor="pointer"
                            onClick={() => setActiveView('followedPlaylists')}
                            fontWeight={activeView === 'followedPlaylists' ? 'bold' : 'normal'}
                            color={activeView === 'followedPlaylists' ? selectedColor : textColor}
                            p={1}
                            borderRadius="md"
                            fontSize= {isMobile ? "xs" : "sm"}
                          >
                            followed playlists
                          </Text>
                          <Text>/</Text>
                          <Text
                              cursor="pointer"
                              onClick={() => setActiveView('following')}
                              fontWeight={activeView === 'following' ? 'bold' : 'normal'}
                              color={activeView === 'following' ? selectedColorB : textColor}
                              p={1}
                              borderRadius="md"
                              fontSize= {isMobile ? "xs" : "sm"}
                          >
                              following
                          </Text>
                          <Text>/</Text>
                          <Text
                            cursor="pointer"
                            onClick={() => setActiveView('followers')}
                            fontWeight={activeView === 'followers' ? 'bold' : 'normal'}
                            color={activeView === 'followers' ? selectedColorB : textColor}
                            p={1}
                            borderRadius="md"
                            fontSize= {isMobile ? "xs" : "sm"}
                          >
                            followers
                          </Text>
                  </HStack>
                </VStack>
                
            {isLoading ? (
                    <Spinner p="10" color="qTurquoise.500" size="xl" />
                  ) : (
                    <VStack width="100%" spacing={6} pb='6'>
                      {activeView === 'playlists' ? (
                        playlists?.length === 0 ? (
                          <Box>No user playlists created</Box>
                        ) : (
                          <SimpleGrid columns={[2, 6]} spacing={[9, 20]} paddingTop={2}>
                            {/* {playlists?.map((playlist) => {
                              const firstFilmId = playlist.playlistFilms?.[0]?._id;
                              const filmCount = playlist.playlistFilms.length;
                              const imagePath = playlist.playlistFilms?.[0]?.tmdbImagePath;
                              const imageBaseUrl = 'https://image.tmdb.org/t/p/original';
                              const defaultImage = "https://images.unsplash.com/photo-1478720568477-152d9b164e26?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
                              const fullImgPath = imagePath ? `${imageBaseUrl}${imagePath}` : defaultImage; */}
                            
                            {/* now handling sorting the all ratings playlist to the top */}
                            {playlists
                              ?.sort((a, b) => {
                                // All Ratings playlist always comes first
                                if (a.isAllRatings) return -1;
                                if (b.isAllRatings) return 1;
                                // For other playlists, sort by creation date (oldest first)
                                return new Date(a.createdAt) - new Date(b.createdAt);
                              })
                              .map((playlist) => {
                                const firstFilmId = playlist.playlistFilms?.[0]?._id;
                                const filmCount = playlist.playlistFilms.length;
                                const imagePath = playlist.playlistFilms?.[0]?.tmdbImagePath;
                                const imageBaseUrl = 'https://image.tmdb.org/t/p/original';
                                const defaultImage = "https://images.unsplash.com/photo-1478720568477-152d9b164e26?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
                                const fullImgPath = imagePath ? `${imageBaseUrl}${imagePath}` : defaultImage;

                              return (
                                <div key={playlist._id}>
                                  <ProfileFilmCard
                                    playlistId={playlist._id}
                                    title={playlist.playlistTitle}
                                    firstFilmId={firstFilmId}
                                    filmCount={filmCount}
                                    fullImgPath={playlist.playlistCoverPic ||fullImgPath}
                                    userProfile={userProfile}
                                    isEditMode={isEditMode}
                                    onRemove={() => handleDeletePlaylist(playlist._id)}
                                  />
                                </div>
                              );
                            })}
                          </SimpleGrid>
                        )
                      ) : activeView === 'followedPlaylists' ? (
                        followedPlaylists?.length === 0 ? (
                          <Box>No followed playlists</Box>
                        ) : (
                          <SimpleGrid columns={[2, 6]} spacing={[9, 20]} paddingTop={2}>
                            {followedPlaylists?.map((playlist) => {
                              const firstFilmId = playlist.playlistFilms?.[0]?._id;
                              const filmCount = playlist.playlistFilms?.length || 0;
                              const imagePath = playlist.playlistFilms?.[0]?.tmdbImagePath;
                              const imageBaseUrl = 'https://image.tmdb.org/t/p/original';
                              const defaultImage = "https://images.unsplash.com/photo-1478720568477-152d9b164e26?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
                              const fullImgPath = imagePath ? `${imageBaseUrl}${imagePath}` : defaultImage;

                              return (
                                <ProfileFilmCard
                                  key={playlist._id}
                                  playlistId={playlist._id}
                                  title={playlist.playlistTitle}
                                  firstFilmId={firstFilmId}
                                  filmCount={filmCount}
                                  fullImgPath={playlist.playlistCoverPic || fullImgPath}
                                  userProfile={playlist.user_id}
                                  isEditMode={false}
                                />
                              );
                            })}
                          </SimpleGrid>
                        )
                      ) : activeView === 'following' ? (
                        userProfile.following?.length === 0 ? (
                          <Box>Not following any users</Box>
                        ) : (
                          <SimpleGrid columns={[2, 6]} spacing={[9, 20]} paddingTop={2}>
                            {userProfile.following?.map((followedUser) => (
                              <ProfileCard
                                key={followedUser._id}
                                userProfile={followedUser}
                                userId={followedUser._id}
                                username={followedUser.username}
                                fullName={followedUser.fullName}
                                userPlaylistCount={followedUser.userPlaylistCount}
                                userRatingCount={followedUser.userRatingCount}
                                size="150px"
                                forceRefresh={true}
                              />
                            ))}
                          </SimpleGrid>
                        )
                      ) : (
                        userProfile.followers?.length === 0 ? (
                          <Box>No followers yet</Box>
                        ) : (
                          <SimpleGrid columns={[2, 6]} spacing={[9, 20]} paddingTop={2}>
                            {userProfile.followers?.map((follower) => (
                              <ProfileCard
                                key={follower._id}
                                userProfile={follower}
                                userId={follower._id}
                                username={follower.username}
                                fullName={follower.fullName}
                                userPlaylistCount={follower.userPlaylistCount}
                                userRatingCount={follower.userRatingCount}
                                size="150px"
                                forceRefresh={true}
                              />
                            ))}
                          </SimpleGrid>
                        )
                      )}
                    </VStack>
                  )}
                
            </VStack>
            <VStack align="center" m='10'>
              {loggedIn && userId === loggedInUserId && activeView === 'playlists' && (
                <VStack spacing={4}>
                  <CreatePlaylistModal 
                    userId={userId}
                    onPlaylistCreated={async () => {
                      // Refresh playlists after creation
                      const playlistsData = await getPlaylists(userId);
                      setPlaylists(playlistsData.playlists);
                    }}
                  />
                  <Button
                    onClick={() => setIsEditMode(!isEditMode)}
                    colorScheme={isEditMode ? "red" : "qPurple"}
                    size="xs" 
                  >
                    {isEditMode ? "Done Editing" : "Edit Contents"}
                  </Button>
                </VStack>
              )}
            </VStack>
            <AlertDialog
              isOpen={isDeleteAlertOpen}
              leastDestructiveRef={undefined}
              onClose={() => {
                setIsDeleteAlertOpen(false);
                setPlaylistToDelete(null);
              }}
            >
              <AlertDialogOverlay>
                <AlertDialogContent 
                  bg="black" 
                  borderColor="white" 
                  borderWidth="3px" 
                  borderRadius='15px'
                >
                  <AlertDialogHeader fontSize="lg" fontWeight="bold" color="white">
                    Delete Playlist
                  </AlertDialogHeader>

                  <AlertDialogBody color="red.600">
                    Are you sure? You can't undo this action afterwards.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button 
                      onClick={() => {
                        setIsDeleteAlertOpen(false);
                        setPlaylistToDelete(null);
                      }}
                      bg="black"
                      color="white"
                      borderColor="white"
                      borderWidth="1px"
                      _hover={{ bg: "gray.800" }}
                    >
                      Cancel
                    </Button>
                    <Button 
                      colorScheme="red" 
                      onClick={confirmDelete} 
                      ml={3}
                      bg="red.500"
                      _hover={{ bg: "red.600" }}
                    >
                      Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </Box>
    </>
    
  );
};

export default ProfilePage;
import React from 'react';
import {Box, Image, VStack, Heading, Spinner, IconButton, useColorModeValue} from '@chakra-ui/react';
import {getQuartileFilmByQId} from '../api';
import { useNavigate, useParams } from 'react-router-dom';
import { IoCloseCircle } from 'react-icons/io5';
const PlaylistCard = (props) => {

    const [isLoading, setIsLoading] = React.useState(true);
    // const [quartileFilmData, setQuartileFilmData] = React.useState({});
    // const [selectedPlaylist, setSelectedPlaylist] = React.useState([]);
    // const [imagePath, setImagePath] = React.useState(null);


    const navigate = useNavigate();
    const {
        playlistId,
        title,
        username,
        firstFilmId,
        userProfile,
        filmCount,
        userId,
        fullImgPath,
        isEditMode,
        onRemove
        
    } = props;

    // const { userId } = useParams();
    const borderColor = useColorModeValue("black", "white");
    const textColor = useColorModeValue("black", "white");
    const bgColor = useColorModeValue("white", "black");

   
    const handleClick = () => {
        // const playlistDetails = {
        //     playlistId,
        //     fullImgPath,
        //     imagePath,
        //     title,
        //     // year,
        // };
        // setSelectedPlaylist(playlistDetails);
        // console.log("UserProfile in ProfileFilmCard:", userProfile);
        navigate(`/profile/${userId}/playlists/${playlistId}`, {
            state: { userProfile }  // Pass userProfile in navigation state
        });
        
    };


    return (
        <>
            <VStack class="containerdiv" position="relative">
                <button onClick={() => {handleClick()}}>
                    <Image
                    src={fullImgPath}
                    alt="Movie Pic Placeholder"
                    class="filmCardFormat"
                    style={{ border: `3px solid ${borderColor}` }}
                    />

                    <Heading class="QCardTitleFormat" letterSpacing={'wide'}>
                    {title}
                    </Heading>
                    <Heading class = "profileCardFullNameFormat" letterSpacing={'wide'} >
                        @{username}
                    </Heading>
                    <Heading class={'QCardYearFormat'}>
                    {filmCount} Films
                    </Heading>
                </button>
                {props.isEditMode && (
                    <IconButton
                    aria-label="Remove playlist"
                    icon={<IoCloseCircle size="100%" />}
                    position="absolute"
                    top={-5}
                    right={-5}
                    width="60px"
                    height="60px"
                    borderRadius="15%"
                    border={`3px solid ${borderColor}`}
                    colorScheme="red"
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onRemove();
                    }}
                    />
                )}
            </VStack>
        </>
    )
};


export default PlaylistCard;
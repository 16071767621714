import React, { useState, useEffect } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  VStack,
  HStack,
  IconButton,
  Text,
  useColorModeValue,
  Spinner,
  Input,
  useToast,
  Heading,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { FaCheck, FaPlus } from 'react-icons/fa';
// icons to be put in the popover display to show on the button whether the film is in the playlist or not
// import { CheckIcon, AddIcon } from '@chakra-ui/icons';
import { getPlaylists, updatePlaylist, createPlaylist, getQuartileFilm } from '../api'; // Assuming you have these API methods

const MobileAddToPlaylistModal = ({ filmId, userId }) => {
  const [playlists, setPlaylists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newPlaylistTitle, setNewPlaylistTitle] = useState('');
  const [qFilmId, setQFilmId] = useState(null);
  const [playlistFilmPresence, setPlaylistFilmPresence] = useState({});
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'black');
  const borderColor = useColorModeValue("black", "white");
  const textColor = useColorModeValue("black", "white");

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchFilmIdAndPlaylists = async () => {
      setIsLoading(true);
      try {
        const filmData = await getQuartileFilm(filmId);
        if (filmData && filmData._id) {
          setQFilmId(filmData._id);
          
          const playlistsData = await getPlaylists(userId);
          // Filter out the All Ratings playlist
          const filteredPlaylists = playlistsData.playlists.filter(playlist => !playlist.isAllRatings);
          setPlaylists(filteredPlaylists);
  
          // Check if the film is in each playlist
          const presence = {};
          filteredPlaylists.forEach(playlist => {
            presence[playlist._id] = playlist.playlistFilms.some(film => film._id === filmData._id);
          });
          setPlaylistFilmPresence(presence);
        } else {
          console.error('Film data is null or _id is missing');
          setQFilmId(null);
        }
      } catch (error) {
        console.error('Error fetching film ID or playlists:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchFilmIdAndPlaylists();
  }, [userId, filmId]);

// remove or add film from playlist
  const handleUpdatePlaylist = async (playlistId, playlistTitle) => {
    try {
      if (playlistFilmPresence[playlistId]) {
        // Remove film from playlist
        await updatePlaylist(playlistId, { $pull: { playlistFilms: qFilmId } });
        setPlaylistFilmPresence(prev => ({ ...prev, [playlistId]: false }));
        toast({
          title: 'Film Removed',
          description: `The film has been REMOVED from your ${playlistTitle} playlist`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Add film to playlist
        await updatePlaylist(playlistId, { $push: { playlistFilms: qFilmId } });
        setPlaylistFilmPresence(prev => ({ ...prev, [playlistId]: true }));
        toast({
          title: 'Film Added',
          description: `The film has been ADDED to your ${playlistTitle} playlist`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: `${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error updating playlist:', error);
    }
  };  // };

  const handleCreatePlaylist = async () => {
    const newPlaylist = {
      user_id: userId,
      playlistTitle: newPlaylistTitle,
      playlistFilms: [qFilmId],
    };

    try {

      const responseData = await createPlaylist(newPlaylist);
      
      toast({
        title: 'Playlist Created',
        description: `The new ${newPlaylistTitle} playlist has been created and the film added to it.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setNewPlaylistTitle('');
      const playlistsData = await getPlaylists(userId);
      setPlaylists(playlistsData.playlists);

      // Update playlistFilmPresence for the new playlist
      setPlaylistFilmPresence(prev => ({
        ...prev,
        [responseData.playlist._id]: true // Assuming the API returns the new playlist with its _id
      }));

    } catch (error) {
      toast({
        title: 'Error',
        description: `There was an error creating the new playlist: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error creating new playlist:', error);
    }
  };

 
  return (
    <>
      <Button onClick={onOpen}>Add to Playlist</Button>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent bg={bgColor}>
        <ModalHeader>
          <Heading letterSpacing='wider' fontFamily='categoryFont' size={'md'}>
            PLAYLISTS
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Spinner />
          ) : (
            <VStack align="stretch" spacing={4}>
              <Input
                placeholder="New Playlist Title"
                value={newPlaylistTitle}
                onChange={(e) => setNewPlaylistTitle(e.target.value)}
              />
              <Button
                onClick={handleCreatePlaylist}
                disabled={!newPlaylistTitle.trim()}
                colorScheme="qPurple"
              >
                Add To New Playlist
              </Button>
              
              <Spacer borderBottom='2px' borderColor='qPurple.400'/>
              
              {playlists.map((playlist) => (
                <HStack key={playlist._id} justify="space-between">
                  <Text>{playlist.playlistTitle}</Text>
                  <IconButton
                    icon={playlistFilmPresence[playlist._id] ? <FaCheck /> : <FaPlus />}
                    colorScheme={playlistFilmPresence[playlist._id] ? "green" : "qPurple"}
                    onClick={() => handleUpdatePlaylist(playlist._id, playlist.playlistTitle)}
                    aria-label={playlistFilmPresence[playlist._id] ? "Remove from playlist" : "Add to playlist"}
                  />
                </HStack>
              ))}
            </VStack>
          )}
        </ModalBody>
        <ModalFooter justifyContent='center' mb='6'>
          <Button colorScheme="qPurple" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    </>
  );
};

export default MobileAddToPlaylistModal;